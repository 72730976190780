<template>
  <div>
    <iq-empty-state
      icon="sync_problem"
      title="Keine Aktivitäten gefunden"
      v-if="activities.length === 0"
    />

    <ol class="feed" v-else>
      <li class="feed--item" v-for="activity in activities" :key="activity.id">
        <time class="date">{{
          new Date(activity.created_at).toLocaleDateString()
        }}</time>
        <span class="text">{{ activity.description }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "activityFeed",
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

a {
  color: var(--iq-blue-500);
}

.feed {
  padding: $padding;
  list-style: none;

  &--item {
    position: relative;
    padding-bottom: 24px;
    padding-left: 24px;
    border-left: 2px solid var(--iq-gray-300);
    transition: $transition;
    cursor: default;

    &:hover {
      border-left: 2px solid var(--iq-blue-500);
    }

    &:hover::after {
      border: 3px solid var(--iq-blue-500);
    }

    &:hover > .date {
      color: var(--iq-blue-500);
    }

    &:last-child {
      border-color: transparent;
      padding-bottom: 0;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -7px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: var(--iq-gray-50);
      border: 3px solid var(--iq-blue-300);
      transition: $transition;
    }

    .date {
      display: block;
      position: relative;
      top: -5px;
      color: #8c96a3;
      text-transform: uppercase;
      font-size: 13px;
    }

    .text {
      position: relative;
      top: -3px;
    }
  }
}
</style>

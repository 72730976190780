<template>
  <iq-card
    title="Projektfortschritt"
    button-icon="chevron_right"
    button-tooltip="Alle Kontakte ansehen"
    @buttonClick="
      redirect({ name: 'Project Contacts', params: { id: projectId } })
    "
  >
    <!-- Progress overview -->
    <div class="progress">
      <div class="progress--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon backlog-avatar-adjuster">
            <md-icon style="color: var(--iq-gray-500)">person</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ count.backlog }}</div>

        <!-- Description -->
        <div class="md-title">Nicht bearbeitet</div>
      </div>

      <div class="progress--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon hold-avatar-adjuster">
            <md-icon style="color: var(--iq-red-500)">pause</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ count.on_hold }}</div>

        <!-- Description -->
        <div class="md-title">Pausiert</div>
      </div>

      <div class="progress--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon progress-avatar-adjuster">
            <md-icon class="md-primary">play_arrow</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ count.in_progress }}</div>

        <!-- Description -->
        <div class="md-title">In Bearbeitung</div>
      </div>

      <div class="progress--item">
        <!-- Icon -->
        <div>
          <md-avatar class="md-avatar-icon done-avatar-adjuster">
            <md-icon style="color: var(--iq-green-500)">task_alt</md-icon>
          </md-avatar>
        </div>

        <!-- Number -->
        <div class="md-title title-adjuster">{{ count.finished }}</div>

        <!-- Description -->
        <div class="md-title">Abgeschlossen</div>
      </div>
    </div>
  </iq-card>
</template>

<script>
import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import loadingMixin from "@/mixins/loadingMixin";
import routingMixin from "@/mixins/routingMixin";

const STATISTICS_URI = Settings.API_URI_STATISTICS;

export default {
  name: "progressOverview",
  mixins: [loadingMixin, routingMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      count: {
        backlog: 0,
        on_hold: 0,
        in_progress: 0,
        finished: 0,
      },
    };
  },
  created() {
    this.getStatusCounts();
  },
  methods: {
    getConfig(method, url, params) {
      let config = {
        method: method,
        url: url,
      };

      if (params) {
        let configParams = new URLSearchParams();
        for (let param in params) {
          configParams.set(param, params[param]);
        }
        config["params"] = configParams;
      }

      return config;
    },
    getStatusCounts() {
      let config = this.getConfig(
        "get",
        `${STATISTICS_URI}/project/count/status`,
        { project_id: this.projectId }
      );

      Interceptor(config)
        .then((response) => {
          this.count = { ...this.count, ...response.data };
          this.toggleLoading();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.progress {
  padding: $padding;
  display: flex;
  flex-wrap: wrap;

  &--item {
    flex: 1;
    min-width: 300px;
    height: 150px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-radius: $border-radius;
    transition: $transition;
    color: var(--iq-gray-400);

    &:hover {
      background-color: var(--iq-gray-50);
    }

    &:first-child:hover {
      color: var(--iq-gray-500);
    }

    &:nth-child(2):hover {
      color: var(--iq-red-500);
    }

    &:nth-child(3):hover {
      color: var(--iq-blue-500);
    }

    &:last-child:hover {
      color: var(--iq-green-500);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 25%;
      border-left: 1px solid var(--iq-gray-300);
      transition: $transition;
    }

    &:hover:before {
      height: 50%;
    }

    &:first-child:hover:before {
      border-left: 4px solid var(--iq-gray-500);
    }

    &:nth-child(2):hover:before {
      border-left: 4px solid var(--iq-red-500);
    }

    &:nth-child(3):hover:before {
      border-left: 4px solid var(--iq-blue-500);
    }

    &:last-child:hover:before {
      border-left: 4px solid var(--iq-green-500);
    }
  }
}

// Adjuster
.backlog-avatar-adjuster {
  background-color: var(--iq-gray-100) !important;
}

.hold-avatar-adjuster {
  background-color: var(--iq-red-100) !important;
}

.progress-avatar-adjuster {
  background-color: var(--iq-blue-100) !important;
}

.done-avatar-adjuster {
  background-color: var(--iq-green-100) !important;
}

.title-adjuster {
  font-size: 48px;
  font-weight: 400;
  margin-right: 1rem;
}
</style>

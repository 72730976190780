<template>
  <iq-card
    title="Qualitätsübersicht"
    button-icon="chevron_right"
    button-tooltip="Alle Kontakte ansehen"
    @buttonClick="
      redirect({ name: 'Project Contacts', params: { id: projectId } })
    "
  >
    <div class="card-body">
      <div class="quality">
        <!-- Loading -->
        <div class="quality--chart--loader" v-if="loading"></div>

        <!-- Chart -->
        <div class="quality--chart" v-else-if="!loading && !empty">
          <div
            class="quality--chart--item"
            :style="'width: ' + percentage.doctor"
            v-if="percentage.doctor !== '0%'"
          >
            {{ percentage.doctor.replace("%", " %") }} Doktoren
          </div>

          <div
            class="quality--chart--item"
            :style="'width: ' + percentage.notDoctor"
            v-if="percentage.notDoctor !== '0%'"
          >
            {{ percentage.notDoctor.replace("%", " %") }} Fachangestellte
          </div>
        </div>

        <!-- Empty -->
        <div class="quality--chart--empty" v-else>Keine Daten vorhanden</div>
      </div>
    </div>
  </iq-card>
</template>

<script>
import Interceptor from "@/services/Interceptor";
import httpConfigMixin from "@/mixins/httpConfigMixin";
import Settings from "@/config";
import loadingMixin from "@/mixins/loadingMixin";
import routingMixin from "@/mixins/routingMixin";

const STATISTICS_URI = Settings.API_URI_STATISTICS;

export default {
  name: "contactQualityOverview",
  mixins: [httpConfigMixin, loadingMixin, routingMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      empty: false,
      percentage: {
        doctor: null,
        notDoctor: null,
      },
    };
  },
  created() {
    this.getProjectQualityCount();
  },
  methods: {
    getProjectQualityCount() {
      let config = this.getConfig(
        "get",
        `${STATISTICS_URI}/project/count/quality`,
        { project_id: this.projectId },
        null
      );

      Interceptor(config)
        .then(async (response) => {
          if (Object.keys(response.data).length !== 0)
            await this.calculatePercentage(
              response.data.true,
              response.data.false
            );
          else this.empty = true;
          this.toggleLoading();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    calculatePercentage(doctor, notDoctor) {
      if (!doctor) doctor = 0;
      if (!notDoctor) notDoctor = 0;
      let total = doctor + notDoctor;
      this.percentage.doctor = `${((doctor / total) * 100).toFixed(0)}%`;
      this.percentage.notDoctor = `${((notDoctor / total) * 100).toFixed(0)}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.card-body {
  padding: $padding;
}

.quality {
  &--chart {
    display: flex;

    &--item {
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.005em;
      line-height: 24px;
      transition: $transition;

      &:first-child {
        background-color: var(--iq-blue-100);
        color: var(--iq-blue-500);
        border-radius: $border-radius 0 0 $border-radius;

        &:hover {
          background-color: var(--iq-blue-200);
        }
      }

      &:only-child {
        border-radius: $border-radius;
      }

      &:nth-child(2) {
        background-color: var(--iq-green-100);
        color: var(--iq-green-500);
        border-radius: 0 $border-radius $border-radius 0;

        &:hover {
          background-color: var(--iq-green-200);
        }
      }
    }
  }
}

.quality--chart--loader {
  height: 75px;
  width: 100%;
  border-radius: $border-radius;
  background-color: var(--iq-gray-100);

  @include skeletonAnimation();
}

@include skeletonKeyframes();

.quality--chart--empty {
  height: 75px;
  width: 100%;
  border-radius: $border-radius;
  background-color: var(--iq-gray-100);
  color: var(--iq-gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 24px;
}
</style>

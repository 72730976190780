<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Neuen Fragebogen erstellen</md-dialog-title>

    <md-dialog-content>
      <md-field>
        <label>Name für den Fragebogen</label>
        <md-input v-model="name" required />
      </md-field>

      <md-field>
        <label>Beschreibung für den Fragebogen</label>
        <md-textarea v-model="description" required />
      </md-field>

      <div class="md-error" style="color: red" v-if="showAlert">
        Bitte fülle alle erforderlichen Felder aus!
      </div>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="emitClose">Abbrechen</md-button>
      <md-button class="md-primary" @click="emitCreate">Erstellen</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
export default {
  name: "surveyCreateDialog",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      showAlert: false,
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    emitCreate() {
      if (!this.name || !this.description) {
        this.showAlert = true;
        return;
      }
      this.$emit("create-survey", this.$data);
    },
  },
};
</script>

<style scoped lang="scss">
.md-dialog-content {
  width: 750px;
}
</style>

<template>
  <div class="project-notice">
    <div class="md-title">
      <md-icon class="md-primary">rule</md-icon> Bisher wurde noch kein
      Fragebogen für dieses Projekt erstellt!
    </div>

    <md-button class="md-primary margin-0" @click="emitCreate"
      >Fragebogen erstellen</md-button
    >
  </div>
</template>

<script>
export default {
  name: "projectSurveyNotice",
  methods: {
    emitCreate() {
      this.$emit("create-survey");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/main";
@import "../../assets/css/mixins";

.project-notice {
  padding: $padding;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--iq-blue-100);
  border: 1px solid var(--iq-blue-500);
  margin-bottom: $margin;
  color: var(--iq-blue-500);
}
</style>

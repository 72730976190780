const httpConfigMixin = {
  methods: {
    getConfig(method, url, params, data) {
      let config = {
        method: method,
        url: url,
      };

      if (params) {
        let configParams = new URLSearchParams();
        for (let param in params) {
          configParams.set(param, params[param]);
        }
        config["params"] = configParams;
      }

      if (data) {
        config["data"] = data;
      }

      return config;
    },
  },
};

export default httpConfigMixin;

<template>
  <div>
    <iq-card title="Alle Kontakte löschen" button-icon="chevron_right" hide-button>
    <div class="card-body">
      <span class="md-body-2">Sollten alle Kontakte gelöscht werden, dann kann diese Aktion mehr nicht rückgängig gemacht werden.</span>

      <md-field>
        <label>Schreiben Sie hier DELETE, um diese Aktion freizuschalten</label>
        <md-input v-model="deleteAccess" placeholder="Schreiben Sie hier DELETE, um diese Aktion freizuschalten" />
      </md-field>

      <md-button @click="handleDelete" :disabled="deleteAccess !== 'DELETE'" class="md-accent" style="width: 100%; margin: 0">Ich verstehe und möchte alle Kontakte löschen</md-button>
    </div>
  </iq-card>

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
    >
      <span>{{ successMessage }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { deleteAllProjectContacts } from "@/services/api/project";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";

export default {
  name: "deleteAllContacts.vue",
  mixins: [
    errorMixin,
    successMixin,
  ],
  data() {
    return {
      deleteAccess: "",
      projectId: "",
    }
  },
  created() {
    this.projectId = this.$route.params.id;
  },
  methods: {
    async handleDelete() {
      try {
        await deleteAllProjectContacts(this.projectId);
        this.deleteAccess = "";
        this.toggleSuccess("Kontakte wurden erfolgreich gelöscht");
      } catch (e) {
        console.error(e);
        this.toggleError("Kontakte konnten nicht gelöscht werden");
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.card-body {
  padding: $padding;
}
</style>
<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Projekt bearbeiten</md-dialog-title>

    <md-dialog-content>
      <div class="md-caption">
        Hier können Sie Ihr Projekt bearbeiten. Dazu müssen Sie nur die Felder
        ausfüllen, welche Sie ändern möchten.
      </div>

      <!-- Project name -->
      <md-field>
        <label>Projektname</label>
        <md-input v-model="name" />
      </md-field>

      <!-- Project status -->
      <md-field>
        <label>Projektstatus</label>
        <md-select v-model="status">
          <md-option value="pending">Ausstehend</md-option>
          <md-option value="active">Aktiv</md-option>
          <md-option value="inactive">Inaktiv</md-option>
          <md-option value="finished">Abgeschlossen</md-option>
        </md-select>
      </md-field>

      <!-- Project ref_id -->
      <md-field>
        <label>Referenz-ID</label>
        <md-input v-model="ref_id" />
      </md-field>

      <!-- Project description -->
      <md-field>
        <label>Projektbeschreibung</label>
        <md-textarea v-model="description" md-autogrow maxlength="200" />
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="emitClose">Abbrechen</md-button>
      <md-button class="md-primary" @click="emitUpdate"
        >Aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";

export default {
  name: "projectUpdate",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      status: "",
      ref_id: "",
      description: "",
    };
  },
  methods: {
    emitUpdate() {
      this.$emit("update-project", this.$data);
      this.reset();
    },
    emitClose() {
      this.$emit("close");
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 750px;
}
</style>

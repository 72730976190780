<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="loading" />

    <!-- Content -->
    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar>
        <template v-slot:left>
          <!-- Button -->
          <md-button
            class="md-primary md-icon-button back-button"
            @click="redirect({ name: 'Projects' })"
          >
            <md-icon>arrow_back</md-icon>
            <md-tooltip md-direction="right"
              >Zurück zur Projektübersicht</md-tooltip
            >
          </md-button>

          <!-- Title -->
          <div class="md-title">{{ project.name }}</div>
          <iq-status-pill :status="project.status" class="margin-left-1" />

          <div class="project-users">
            <div
              class="project-users--user"
              v-for="(user, index) in users"
              :key="user.id"
              :style="getUserIconPosition(index)"
            >
              {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
              <md-tooltip md-direction="right"
                >{{ user.first_name }} {{ user.last_name }}</md-tooltip
              >
            </div>
          </div>
        </template>

        <template v-slot:right>
          <!-- Contacts button -->
          <md-button
            class="md-button md-primary md-icon-button margin-0"
            @click="
              redirect({ name: 'Project Contacts', params: { id: projectId } })
            "
          >
            <md-icon>people</md-icon>
            <md-tooltip md-direction="left">Alle Kontakte ansehen</md-tooltip>
          </md-button>

          <!-- Project settings -->
          <md-menu md-align-trigger v-if="isComponentActive(['admin'])">
            <md-button
              class="md-primary md-icon-button margin-left-1"
              md-menu-trigger
            >
              <md-icon>settings</md-icon>
            </md-button>

            <md-menu-content>
              <!-- Project edit -->
              <md-menu-item @click="toggleDialog('projectUpdate')">
                <md-icon>edit</md-icon>
                <span>Projekt bearbeiten</span>
              </md-menu-item>
              <!-- Project edit -->
              <md-menu-item @click="handleSurveyEditClick">
                <md-icon>rule</md-icon>
                <span>Fragebogen bearbeiten</span>
              </md-menu-item>
              <!-- Project users edit -->
              <md-menu-item
                @click="
                  redirect({
                    name: 'Project User Management',
                    params: { id: projectId },
                  })
                "
              >
                <md-icon>manage_accounts</md-icon>
                <span>Benutzer verwalten</span>
              </md-menu-item>
              <!-- Project connect id -->
              <md-menu-item @click="toggleDialog('connectorUrls')">
                <md-icon>content_copy</md-icon>
                <span>Daten Connectors</span>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </template>
      </iq-toolbar>

      <div class="wrapper">
        <div class="holder-left" style="overflow: auto">
          <project-survey-notice
            @create-survey="toggleDialog('surveyCreate')"
            v-if="!survey && isComponentActive(['admin'])"
          />

          <progress-overview :project-id="projectId" />

          <contact-quality-overview
            :project-id="projectId"
            style="margin-top: 1rem"
          />

          <delete-all-contacts v-if="isComponentActive(['admin'])"
            style="margin-top: 1rem" />
        </div>

        <div class="holder-right">
          <!-- Header -->
          <div class="md-title">Aktivitätsfeed</div>

          <activity-feed :activities="activities" />
        </div>
      </div>
    </div>

    <!-- Dialog -->
    <project-update
      :is-active="dialogs.projectUpdate"
      @close="toggleDialog('projectUpdate')"
      @update-project="updateProject"
    />

    <survey-create-dialog
      :is-active="dialogs.surveyCreate"
      @close="toggleDialog('surveyCreate')"
      @create-survey="createSurvey"
    />

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
    >
      <span>{{ successMessage }}</span>
    </md-snackbar>

    <md-dialog :md-active.sync="dialogs.connectorUrls">
      <md-dialog-title>Daten Connector URLs</md-dialog-title>
      <md-dialog-content style="min-width: 900px">
        <md-checkbox v-model="showApiKeyInUrl">API Key in der URL</md-checkbox>
        <md-field>
          <label>Projekt</label>
          <md-input readonly v-model="connectors.project" />
        </md-field>
        <md-field>
          <label>Kontakte</label>
          <md-input readonly v-model="connectors.contacts" />
        </md-field>
        <md-field>
          <label>Antworten und Fragen</label>
          <md-input readonly v-model="connectors.answers" />
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="toggleDialog('connectorUrls')">schließen</md-button>
      </md-dialog-actions>
    </md-dialog>
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import routingMixin from "@/mixins/routingMixin";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";
import dialogMixin from "@/mixins/dialogMixin";
import ProgressOverview from "@/components/project/progressOverview";
import ActivityFeed from "@/components/project/activityFeed";
import ContactQualityOverview from "@/components/project/contactQualityOverview";
import {
  getProject,
  getProjectActivities,
  getProjectSurvey,
  getProjectUsers,
  updateProject,
} from "@/services/api/project";
import ProjectUpdate from "@/components/project/projectUpdate";
import SurveyCreateDialog from "@/components/project/surveyCreateDialog";
import { createSurvey } from "@/services/api/survey";
import ProjectSurveyNotice from "@/components/project/projectSurveyNotice";
import accessControlMixin from "@/mixins/accessControlMixin";
import DeleteAllContacts from "@/components/project/deleteAllContacts";

export default {
  name: "Test",
  components: {
    DeleteAllContacts,
    ProjectSurveyNotice,
    SurveyCreateDialog,
    ProjectUpdate,
    ContactQualityOverview,
    ActivityFeed,
    ProgressOverview,
  },
  mixins: [
    loadingMixin,
    routingMixin,
    errorMixin,
    successMixin,
    dialogMixin,
    accessControlMixin,
  ],
  data() {
    return {
      projectId: null,
      project: {},
      survey: {},
      users: [],
      activities: [],
      dialogs: {
        projectUpdate: false,
        surveyCreate: false,
        connectorUrls: false,
      },
      showApiKeyInUrl: false,
      connectors: {
        project: null,
        contacts: null,
        answers: null,
      }
    };
  },
  created() {
    this.projectId = this.$route.params.id;
    this.$store.commit("setCurrentProject", this.$route.params.id);
    this.$store.commit("setCurrentPageIndex", 1);
    this.getProject();
    this.setupConnectorUrls();
  },
  watch: {
    showApiKeyInUrl(newVal) {
      this.setupConnectorUrls(newVal);
    },
  },
  methods: {
    async getProject() {
      try {
        this.project = await getProject(this.projectId);
        this.survey = await getProjectSurvey(this.projectId);
        this.activities = await getProjectActivities(this.projectId);
        this.users = await getProjectUsers(this.projectId);
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
    async updateProject(data) {
      try {
        this.project = await updateProject(this.projectId, data);
        this.toggleDialog("projectUpdate");
        this.toggleSuccess("Project wurde erfolgreich aktualisiert");
        await this.getProjectActivities();
      } catch (error) {
        this.toggleError("Projekt konnte nicht aktualisiert werden");
      }
    },
    async getProjectActivities() {
      this.activities = await getProjectActivities(this.projectId);
    },
    async createSurvey(data) {
      try {
        this.survey = await createSurvey(this.projectId, data);
        this.toggleDialog("surveyCreate");
        this.toggleSuccess("Fragebogen wurde erfolgreich erstellt");
        await this.getProjectActivities();
      } catch (error) {
        this.toggleDialog("surveyCreate");
        this.toggleError("Fragebogen konnte nicht erstellt werden");
      }
    },
    setupConnectorUrls(useApiKeyInUrl = false) {
      const baseUrl = `https://app-hpms.aifinity.io/api/v1/connect/`;
      const searchParam = `?project_id=${this.projectId}`;
      const apiKey = useApiKeyInUrl ? '&api_key=REPLACE_ME' : '';

      this.connectors.project = `${baseUrl}project${searchParam}${apiKey}`
      this.connectors.contacts = `${baseUrl}contacts${searchParam}${apiKey}`
      this.connectors.answers = `${baseUrl}answers${searchParam}${apiKey}`
    },
    async copyDataUrl() {
      try {
        if (!window.navigator.clipboard) {
          return;
        }

        const url = `https://app-hpms.aifinity.io/api/v1/connect/?project_id=${this.projectId}`;
        await window.navigator.clipboard.writeText(url);
        this.toggleSuccess("Erfolgreich kopiert");
      } catch (error) {
        console.error(error);
        this.toggleError("Kopieren fehlgeschlagen")
      }
    },
    getUserIconPosition(index) {
      if (index === 0) return "left: 0";
      else return `left: -${index}0px`;
    },
    handleSurveyEditClick() {
      if (this.survey)
        this.redirect({
          name: "Create new survey",
          params: { id: this.projectId },
        });
      else this.toggleDialog("surveyCreate");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";

.holder-left {
  height: 100%;
  width: 80%;
  padding: $padding;
  border-right: 1px solid var(--iq-gray-300);
}

.holder-right {
  height: 100%;
  width: 20%;
  padding: $padding;
  overflow: auto;
}

.back-button {
  margin: 0 0.5rem 0 0;
}

.project-users {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: $margin;

  &--user {
    padding: 0.5rem;
    width: 40px;
    text-align: center;
    border: 2px solid white;
    border-radius: 50%;
    position: relative;
    background-color: var(--iq-blue-100);
    color: var(--iq-blue-500);
    transition: $transition;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      z-index: 10000;
    }
  }
}
</style>
